import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "slabasic/src/helper/colors";
import store from "@/store/store";
import en from "vuetify/lib/locale/en";
import de from "vuetify/lib/locale/de";
import fr from "vuetify/lib/locale/fr";
import es from "vuetify/lib/locale/es";
import sv from "vuetify/lib/locale/sv";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {en, de, fr, es, sv},
        current: store.getters.getLanguage,
    },
    theme: {
        lang: {
            locales: {en, de, fr, es, sv},
            current: store.getters.getLanguage,
        },
        dark: store.getters.getTheme === 'dark',
        themes: {
            light: {
                primary: colors.primary,
                secondary: colors.secondary,
                accent: colors.accent,
                danger: colors.danger,
                warning: colors.warning,
                success: colors.success,
            },
            dark: {
                primary: colors.secondary,
                secondary: colors.primary,
                accent: colors.accent,
                danger: colors.danger,
                warning: colors.warning,
                success: colors.success,
            }
        },
        iconfont: 'mdi'
    }
});
