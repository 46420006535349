export default {
  cancel: 'Cancelar',
  create: 'Crear',
  close: 'Cerca',
  id: 'IDENTIFICACIÓN',
  delete: 'Borrar',
  save: 'Ahorrar',
  saveChanges: 'Guardar cambios',
  date: 'Fecha',
  active: 'activo',
  inactive: 'inactivo',
  pending: 'Pendiente',
  passwordReset: 'Restablecer la contraseña',
  deleted: 'deleted',
  organization: 'Organización',
  organizations: 'Organizaciones',
  noDataAvailable: 'No hay datos disponibles',
  quantity: 'Cantidad',
  send: 'Enviar',
  reset: 'Reiniciar',
  exportToExcel: 'Exportar a Excel',
  person: 'Persona',
  status: 'Estado',
  admin: 'admin',
  edit: 'Editar',
  user: 'user',
  none: 'none',
  leave: 'Dejar',
  name: 'Nombre',
  type: 'Tipo',
  selectAll: 'Seleccionar todo',
  view: 'Vista',
  search: 'Buscar',
  time: 'Tiempo',
  yes: 'Sí',
  no: 'No',
  ok: 'De acuerdo',
  download: 'Descargar',
  appLauncher: {
    analytics: 'Analítica',
    management: 'Gestión',
    bolts: 'Perno',
    routers: 'Enrutadores',
    boltsManagement: 'Gestión de pernos',
    configuration: 'Configuración',
    config: 'Configuración',
    production: 'Producción',
  },
  footer: {
    poweredBy: 'Impulsado por',
    termsConditions: 'Términos y condiciones',
    dataPrivacy: 'Datos y privacidad',
    contact: 'Contacto',
  },
  success: {
    activateEmailSuccessful: 'La activación fue exitosa.',
    resendActivationMail: 'El correo de activación fue resentido con éxito.',
    resetPasswordSuccess: 'Su contraseña se ha restablecido correctamente.',
    organizationUpdated: 'La organización se actualizó con éxito.',
    organizationAdminUpdated: 'El administrador de la organización se actualizó con éxito.Consulte los derechos del antiguo administrador de la organización.',
    organizationCreated: 'La organización fue creada con éxito.',
    messageWasSent: 'El mensaje fue enviado con éxito.',
    boltsWereMoved: 'Los tornillos fueron movidos con éxito.',
    passwordCopied: 'La contraseña generada se ha copiado al portapapeles.',
    userAccountWasActivated: 'La cuenta de usuario se activó con éxito.',
    passwordWasChange: 'La contraseña fue cambiada.Inicie sesión.',
  },
  errors: {
    invalidEmail: 'Correo electrónico no válido',
    required: 'required',
    cannotBeZero: 'Este campo no puede ser cero',
    passwordFormat: 'La contraseña debe contener un mayúscula, un carácter minúscula, un número y un carácter especial',
    numberFormat: 'El campo solo puede contener valores numéricos.',
    minimumItem: 'Debe agregar un elemento mínimo a la tabla.',
    numberDecimalFormat: 'El campo solo puede contener valores numéricos con un lugar decimal.',
    numberDecimalFormatLong: 'Este campo solo puede contener valores numéricos con lugares decimales.',
    savingData: 'Hubo un error al guardar los datos.',
    wrongFileType: 'Tipo de archivo incorrecto.Puedes subir',
    loadedShouldLarger: 'El valor cargado no puede ser menor que el valor descargado.',
    shouldBeLess: 'Este valor debe ser menor que el valor %{valor}.',
    uploadingFile: 'Hubo un error al cargar el archivo.',
    routerConfigTemplateFormat: 'La plantilla del enrutador debe ser un JSON válido y contener: "Routerid", "OrganizationId", "SiteId", "Implementación", "Routersn", "Apikey", "SSID", "Contraseña" para ser válidos',
  },
  warning: {
    areYouSureDelete: '¿Está seguro de que desea eliminar este elemento?',
    permanentlyDeleteItem: 'Estás a punto de eliminar permanentemente un artículo.',
    thisActionPermanent: 'Esta acción es permanente.',
    areYouSureLeave: '¿Estás seguro de que quieres abandonar esta página?',
    thereAreUnsavedChanges: 'Hay cambios no salvos.',
    confirmLeavePage: 'Confirmar la página de dejar',
    siteCanNotBeDeactivated: 'El sitio no se puede desactivar ya que tiene máquinas o enrutadores adjuntos.',
    machineCanNotBeDeactivated: 'La máquina no se puede desactivar ya que tiene juntas conectadas.',
    jointCanNotBeDeactivated: 'La junta no se puede desactivar ya que tiene pernos unidos.',
    receptionShouldContainBolt: 'La recepción debe contener al menos un perno.',
    routerBrandCanNotBeDeactivated: 'El enrutador no se puede eliminar ya que tiene modelos adjuntos.',
    areYouSureChangeStatus: '¿Está seguro de que desea cambiar el estado?',
    areYouSureMoveBolts: '¿Estás seguro de que quieres mover los pernos seleccionados a otra organización?',
    areYouSureMoveBoltsReset: 'Los pernos en movimiento a granel los asignarán a otra organización y cambiarán su estado a "configurado".¿Estás seguro de que quieres continuar?',
    areYouSureMoveAndResolveBolts: 'Los pernos con notificaciones activas no se pueden mover.<br/> ¿Le gustaría resolver las notificaciones y mover los pernos?',
    someRightsAreChanged: 'Se cambian algunos derechos.',
    automaticallyLoggedOut: 'Se registrará automáticamente.',
    deleteWithHistory: '¿Quieres eliminar el informe con el historial?',
  },
  authentication: {
    email: 'Correo electrónico',
    password: 'Contraseña',
    newPassword: 'Nueva contraseña',
    repeatNewPassword: 'Repita la nueva contraseña',
    keepMeLoggedIn: 'Mantenme conectado',
    resetPassword: 'Restablecer contraseña',
    login: 'Acceso',
    passwordResetEmail: 'Se le enviará un correo electrónico de restablecimiento de contraseña.',
    passwordResetSent: 'El correo electrónico de restablecimiento de su contraseña se envió a',
    passwordResetNotArrived: 'El correo electrónico tarda unos minutos en llegar.¿Todavía no hay correo electrónico después de 10 minutos?Reenviarlo a continuación.',
    passwordsNotMatch: 'Contraseña y confirmar la contraseña no coincide.',
    confirmAccount: 'Cuenta de confirmación',
    strainlabsMonitoringSolution: 'El sistema Stravlabs es una solución completa de monitoreo de precarga de pernos.',
    forMoreInformation: 'Para obtener más información sobre nuestro software analítico, %{Contactus} o vaya a %{stravlabsanalytics}.',
    contactUs: 'contáctanos',
    strainlabsAnalytics: 'Análisis stravlabs',
    savePassword: 'Guardar contraseña',
  },
  profile: {
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    language: 'Idioma',
    confirmNewPassword: 'Confirmar una nueva contraseña',
    currentPassword: 'Contraseña actual',
    signOut: 'Desconectar',
    myAccount: 'Mi cuenta',
    english: 'Ingles',
    german: 'Alemana',
    swedish: 'Sueca',
    spanish: 'Español',
    french: 'Francés',
    newEmail: 'Nuevo correo electrónico',
    confirmNewEmail: 'Confirmar nuevo correo electrónico',
    emailsNotMatch: 'Nuevo correo electrónico y confirmar el nuevo correo electrónico no coincide.',
    emailShouldBeDifferent: 'El nuevo correo electrónico debe ser diferente del correo electrónico actual.',
    currentEmail: 'Correo electrónico actual',
    light: 'Light',
    dark: 'Dark',
    notificationPreferences: 'Preferencias de notificación',
  },
  contactUs: {
    message: 'Mensaje',
  },
  management: {
    organization: {
      companyName: 'nombre de empresa',
      companyNo: 'Compañía No.',
      theme: 'Tema',
      vatId: 'ID de IVA',
      country: 'País',
      city: 'Ciudad',
      county: 'Condado',
      zipCode: 'Código postal',
      street: 'Calle',
      address: 'DIRECCIÓN',
      phone: 'Teléfono',
      company: 'Compañía',
      contactPerson: 'Persona de contacto',
      companyAddress: 'Dirección de la empresa',
      billingAddress: 'Dirección de Envio',
      useCompanyAddressForBilling: 'Use la dirección de la empresa para la facturación',
      useContactPerson: 'Use la persona de contacto para administrador',
      subscription: 'Suscripción',
    },
    organizationRights: 'Derechos de organización',
    siteRights: 'Derechos del sitio',
    configure: 'Configurar',
    read: 'Leer',
    oem: 'OEM',
    euo: 'EUO',
    endUser: 'Usuario final |Usuarios finales',
    users: 'Usuarios',
    username: 'Nombre de usuario',
    access: 'Acceso',
    usersRights: 'Derechos de los usuarios',
    endUserOrganization: 'Organización de usuarios finales',
    applications: 'Aplicaciones',
    changeAdmin: 'Cambiar administrador',
    notificationTemplate: 'Plantilla de notificación',
    notificationTemplates: 'Plantillas de notificación',
    notificationTemplateEdit: 'Plantilla de notificación',
    report: 'Informe',
    reports: 'Informes',
    reportHistory: 'Historial de informes',
    reportEdit: 'Informes',
    lastSent: 'Último enviado',
    sentDatetime: 'Enviado a la hora del tiempo',
    downloadLink: 'Enlace de descarga',
    recipients: 'Destinatarios',
    frequency: 'Frecuencia',
    selectTemplate: 'Seleccione una plantilla de notificación o configela para usted mismo',
    selectFrequency: 'Seleccione una frecuencia',
    reportSchedule: 'Horario del informe (UTC)',
  },
  bolts: {
    orders: 'Órdenes',
    receptions: 'Recepciones',
    configuration: 'Configuración',
    boltType: 'Tipo de perno',
    iso: 'ISO',
    size: 'Tamaño',
    strength: 'Fortaleza',
    length: 'Longitud',
    proofLoad: 'Carga de prueba',
    status: 'Estado',
    exportToExcel: 'Exportar a Excel',
    serialNumber: 'Número de serie',
    name: 'Nombre',
    quality: 'Calidad',
    boltId: 'ID de perno',
    databaseRow: 'Fila de base de datos',
    boltDatabaseRow: 'Fila de base de datos de pernos',
    serialNo: 'Serial No',
    loaded: 'OLOGADO',
    unloaded: 'UNLOGADO',
    editBolt: 'Editar perno',
    site: 'Sitio |Sitios',
    machine: 'Máquina |Máquinas',
    joint: 'Articulación |Articulaciones',
    boltName: 'Nombre de perno',
    test: 'Prueba',
    production: 'Producción',
    boltHistory: 'Historia de perno',
    tensionHistory: 'Historia de tensión',
    changeType: 'Tipo de cambio',
    changeBy: 'Cambiar',
    deallocateBolt: 'Desalentador',
    areYouSureDeallocate: '¿Estás seguro de que quieres desasignar este tornillo?',
    bulkOrganizationChange: 'Cambio de organización a granel',
    changeOrganization: 'Organización de cambio',
    moveBolts: 'Mover pernos',
    kFactor: 'K Factor',
  },
  configuration: {
    configuration: 'Configuración',
    boltType: 'Tipo de perno',
    createBoltType: 'Crear tipo de perno',
    editBoltType: 'Editar Tipo de perno',
    createSize: 'Crear tamaño',
    editSize: 'Tamaño de edición',
    createISO: 'Crear ISO',
    editISO: 'Editar ISO',
    editStrength: 'Editar fuerza',
    createStrength: 'Crear fuerza',
    editProofLoad: 'Editar carga de prueba',
    createProofLoad: 'Crear carga de prueba',
    description: 'Descripción',
  },
  receptions: {
    receptionNo: 'Recepción No.',
    organization: 'Organización',
    note: 'note',
    status: 'Status',
    no: 'No.',
    quantity: 'Cantidad',
    receptionDetails: 'Detalles de recepción',
    editReception: 'Editar recepción',
    createReception: 'Crear recepción',
    editReceptionDetail: 'Editar detalle de recepción',
    createReceptionDetail: 'Crear detalles de recepción',
    batchNr: 'Número de lotes',
    programmed: 'programado',
    processPerformer: 'Intérprete',
    new: 'new',
    planned: 'planado',
    tempCalibrated: 'Temperatura calibrada',
    ready: 'Listo',
    finished: 'finished',
    receivingOrganization: 'Organización receptora',
    finalReceivingOrganization: 'Organización receptor final',
    moveToOrderingOrganization: 'Mover a la organización de pedidos',
  },
  orders: {
    orderDetails: 'Detalles del pedido',
    orderNo: 'Número de pedido',
  },
  boltStatus: {
    ordered: 'ordido',
    replaced: 'replemento',
    misbehaving: 'Mal comportamiento',
    accepted: 'aceptado',
    received: 'RECEIVE',
    maintenance: 'Mantenimiento',
    configured: 'configurado',
    readyForDeploy: 'Listo para implementar',
    rogue: 'rogue',
  },
  config: {
    sites: 'Sitios',
    offset: 'Compensar',
    machines: 'Máquinas',
    joints: 'Articulaciones',
    siteName: 'Nombre del sitio',
    jointName: 'Nombre conjunto',
    select: 'Seleccionar',
    timezone: 'Zona horaria',
    latitude: 'Latitud',
    longitude: 'Longitud',
    searchForOrganizations: 'Buscar OEM y organización de usuario final',
    searchForEUO: 'Buscar la organización del usuario final',
    floorPlan: 'Planta',
    map: 'Mapa',
    machineName: 'Nombre de la máquina',
    machineImage: 'Imagen de la máquina',
    minimumAllowedLoad: 'Carga crítica mínima',
    minimumSafetyLoad: 'Carga de advertencia mínima',
    maximumSafetyLoad: 'Carga de advertencia máxima',
    apiKey: 'clave api',
    wifiSSID: 'Ssid wifi',
    wifi: 'Wifi',
    downloadConfigFile: 'download File de configuración',
    configFile: 'config file',
    allChildWillDeactivated: 'Este %{ítem} y todas sus dependencias se desactivarán.',
    allChildWillActivated: 'THIS %{ítem} y todas sus dependencias se activarán.',
    routerName: 'Nombre del enrutador',
  },
  imageUploader: {
    dragAndDrop: 'Arrastre y suelte aquí',
    uploadLogo: 'Subir logotipo',
    uploadFloorPlan: 'UPLOAD PLAN',
    uploadMachineImage: 'Imagen de la máquina de carga',
    uploadJointImage: 'Imagen de unión de carga',
    logoAspectRatio: 'Cargue la relación de aspecto 4: 1 .png o .jpg logotipo',
    anyAspectRatio: 'Cualquier relación de aspecto .png o .jpg',
    changeImage: 'Cambiar imagen',
  },
  tooltips: {
    fullScreen: 'Pantalla completa',
    exitFullScreen: 'exit full screen',
    fitToWindow: 'fit a la ventana',
    actualSize: 'Tamaño real',
    zoomIn: 'Dar un golpe de zoom',
    zoomOut: 'Alejarse',
    history: 'Historia',
    activate: 'Activar',
    inactivate: 'Inactivar',
    accessRights: 'Derechos de acceso',
    manageAs: 'Manejar como',
    resendConfirmationMail: 'Reenviar el correo de confirmación de la cuenta',
    view: 'Vista',
    setTemporaryPassword: 'Establecer contraseña temporal',
    showPassword: 'Mostrar contraseña',
    hidePassword: 'Ocultar contraseña',
    generatePassword: 'Generar contraseña',
    cannotEdit: 'No se puede editar una recepción con pernos activos',
    downloadLatest: 'Descargar la última versión',
  },
  imageMarker: {
    dropPin: 'Estirador',
    done: 'Hecho',
  },
  routers: {
    brand: 'Marca |Marcas',
    brandModels: 'Modelos de marca',
    brandName: 'Marca',
    editRouterBrand: 'Editar marca de enrutador',
    createRouterBrand: 'Crear marca de enrutador',
    editRouterModel: 'Editar modelo de enrutador',
    createRouterModel: 'Crear modelo de enrutador',
    brandId: 'ID de marca',
    routerName: 'Nombre del enrutador',
    model: 'Modelo',
    routerConfigTemplate: 'Plantilla de configuración del enrutador',
    serial: 'De serie',
    routerDescription: 'Descripción del enrutador',
    routerBrand: 'Marca de enrutador',
    routerModel: 'Modelo de enrutador',
    deployment: 'Despliegue',
    development: 'Desarrollo',
  },
  analytics: {
    notification: 'Notificación |Notificaciones',
    severity: 'Gravedad',
    title: 'Título',
    critical: 'Crítico |Crítico',
    warning: 'Advertencia |Advertencias',
    information: 'Información |Información',
    image: 'Imagen',
    list: 'Lista',
    setMaintenance: 'Establecer mantenimiento',
    setActive: 'Establecer activo',
    machine: 'Máquina |Máquinas',
    joint: 'Articulación |Articulaciones',
    bolt: 'Perno |Perno',
    activeBolts: 'Pernos activos',
    totalBoltsNo: 'Pernos totales no',
    router: 'Enrutador |Enrutadores',
    thereAreNoMachines: 'No hay máquinas.',
    thereAreNoSites: 'No hay sitios.',
    thereAreNoJoints: 'No hay articulaciones.',
    thereAreNoBolts: 'No hay pernos.',
    thereAreNoRouters: 'No hay enrutadores.',
    thereAreNoNotifications: 'No hay notificaciones.',
    variation: 'Variación',
    deviation: 'Desviación',
    stock: 'Existencias',
    realTime: 'Tiempo real',
    tension: 'Tensión',
    temperature: 'Temperatura',
    battery: 'Batería',
    time: 'Tiempo (sitio)',
    rssi: 'RSSI (DB)',
    readings: 'Lectura',
    minutes: 'Minutos',
    hours: 'Horas',
    days: 'Días',
    granularity: 'Granularidad',
    resolveNotification: 'Resolver notificación',
    resolve: 'Resolver',
    solved: 'SOLED',
    unsolved: 'No resuelto',
    system: 'Sistema',
    thereAreNoDataReadings: 'No hay lecturas de datos para este período.',
    filter: 'Filtrar',
    dataReadings: 'Lecturas de datos',
    all: 'all',
    startBoltId: 'Iniciar ID de perno',
    endBoltId: 'ID de perno final',
    startBoltDatabaseRow: 'Iniciar la fila de la base de datos de Bolt',
    endBoltDatabaseRow: 'Fin de la base de datos de Bolt',
    between: 'Entre',
    above: 'Arriba',
    below: 'Abajo',
    outside: 'Afuera',
    minTension: 'Mínimo de tensión',
    maxTension: 'Tensión máxima',
    tensionValue: 'Valor tensión',
    temperatureValue: 'valor de temperatura',
    minTemperature: 'Temperatura mínima',
    maxTemperature: 'Temperatura máxima',
    statistics: 'Estadística',
    eventTypes: 'Tipos de eventos',
    containsText: 'Contiene texto',
    logs: 'Registro',
    SLALogs: 'Registros de SLA',
    oktaLogs: 'Registros de OKTA',
    usersLogins: "User's logins",
    lastLogin: 'Último inicio de sesión',
    lastAccess: 'Último acceso',
    accessType: 'Tipo de acceso',
    oktaLog: 'Registro de Okta',
    averagesVariation: 'Promedios (variación)',
    individualDeviation: 'Individuo (desviación)',
    dynamic: 'dynamic',
    savedFilters: 'Filtros guardados',
    saveAdvancedFilter: 'Guardar filtro avanzado',
    filterName: 'Nombre de filtro',
    testBench: 'Banco de pruebas',
    details: 'Detalles',
    resolveSelected: 'Resolver seleccionado',
    resolveAll: 'Resolver todo',
    resolveAllNotifications: 'Resolver todas las notificaciones',
    resolveForSeverity: 'Resolver por gravedad',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    boltsInPosition: 'Pernos en posición',
    routersInPosition: 'Enrutadores en posición',
    online: 'En línea',
    offline: 'offline',
    availableToPosition: 'Disponible para la posición',
    overview: 'Descripción general',
    lastAverageTension: 'Tensión de último promedio',
    lastTotalTension: 'Última tensión total',
    lastTension: 'Última tensión',
    addedBy: 'Agregado por',
    callType: 'Tipo de llamada',
    callScope: 'Alcance de la llamada',
    request: 'Pedido',
    response: 'Respuesta',
    success: 'Éxito',
    failed: 'failed',
    rawTension: 'Tensión cruda',
    boltSelected: 'Perno seleccionado | Pernos seleccionados',
    outOfDelayed: 'de la cual {Count} ha retrasado el contacto | de la cual {Count} ha retrasado el contacto',
    missingData: 'Datos faltantes',
  },
  notifications: {
    tensionTooLow: 'Tensión demasiado baja',
    tensionTooHigh: 'Tensión demasiado alta',
    temperatureTooLow: 'Temperatura demasiado baja',
    temperatureTooHigh: 'Temperatura demasiado alta',
    battery: 'Batería',
    rssi: 'RSSI',
    lostPackage: 'Paquete perdido'
  }
}