export default {
  cancel: 'Avboka',
  create: 'Skapa',
  close: 'Nära',
  id: 'Id',
  delete: 'Radera',
  save: 'Spara',
  saveChanges: 'Spara förändringar',
  date: 'Datum',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  pending: 'I avvaktan på',
  passwordReset: 'Återställning av lösenord',
  deleted: 'Raderad',
  organization: 'Organisation',
  organizations: 'Organisationer',
  noDataAvailable: 'Inga data tillgängliga',
  quantity: 'Kvantitet',
  send: 'Skicka',
  reset: 'Återställa',
  exportToExcel: 'Exportera till Excel',
  person: 'Person',
  status: 'Status',
  edit: 'Redigera',
  admin: 'Admin',
  user: 'Användare',
  none: 'Ingen',
  leave: 'Lämna',
  name: 'Namn',
  type: 'Typ',
  selectAll: 'Välj alla',
  view: 'Se',
  search: 'Söka',
  time: 'Tid',
  yes: 'Ja',
  no: 'Inga.',
  ok: 'Ok',
  download: 'Ladda ner',
  appLauncher: {
    analytics: 'Analys',
    management: 'Förvaltning',
    bolts: 'Bultar',
    routers: 'Routrar',
    boltsManagement: 'Bultarhantering',
    configuration: 'Konfiguration',
    config: 'Konfigurering',
    production: 'Produktion',
  },
  footer: {
    poweredBy: 'Drivs av',
    termsConditions: 'Villkor och villkor',
    dataPrivacy: 'Data och integritet',
    contact: 'Kontakta',
  },
  success: {
    activateEmailSuccessful: 'Aktiveringen var framgångsrik.',
    resendActivationMail: 'Aktiveringsposten hölls framgångsrikt.',
    resetPasswordSuccess: 'Ditt lösenord har återställts framgångsrikt.',
    organizationUpdated: 'Organisationen uppdaterades framgångsrikt.',
    organizationAdminUpdated: 'Organisationsadministratören uppdaterades framgångsrikt.Kontrollera rättigheterna för den gamla organisationsadministratören.',
    organizationCreated: 'Organisationen skapades framgångsrikt.',
    messageWasSent: 'Meddelandet skickades framgångsrikt.',
    boltsWereMoved: 'Bultarna flyttades framgångsrikt.',
    passwordCopied: 'Genererat lösenord har kopierats till Urklipp.',
    userAccountWasActivated: 'Användarkontot aktiverades framgångsrikt.',
    passwordWasChange: 'Lösenordet ändrades.Logga in.',
  },
  errors: {
    invalidEmail: 'Ogiltig e -post',
    required: 'Nödvändig',
    cannotBeZero: 'Detta fält kan inte vara noll',
    passwordFormat: 'Lösenordet ska innehålla en versaler, små tecken, ett nummer och en speciell karaktär',
    numberFormat: 'Fältet kan endast innehålla numeriska värden.',
    minimumItem: 'Du bör lägga till minst ett objekt i tabellen.',
    numberDecimalFormat: 'Fältet kan endast innehålla numeriska värden med en decimal.',
    numberDecimalFormatLong: 'Detta fält kan endast innehålla numeriska värden med decimaler.',
    savingData: 'Det fanns ett fel när du sparade uppgifterna.',
    wrongFileType: 'Fel filtyp.Du kan ladda upp',
    loadedShouldLarger: 'Det laddade värdet kan inte vara mindre än det lossade värdet.',
    shouldBeLess: 'Detta värde bör vara mindre än %{värde} -värdet.',
    uploadingFile: 'Det fanns ett fel när du laddade upp filen.',
    routerConfigTemplateFormat: 'Routermall måste vara en giltig JSON och innehålla: "routerid", "organisationId", "SiteId", "Distribution", "Routersn", "Apikey", "SSID", "Password" för att vara giltiga att vara giltiga',
  },
  warning: {
    areYouSureDelete: 'Är du säker på att du vill ta bort det här objektet?',
    permanentlyDeleteItem: 'Du håller på att ta bort ett objekt permanent.',
    thisActionPermanent: 'Denna åtgärd är permanent.',
    areYouSureLeave: 'Är du säker på att du vill lämna den här sidan?',
    thereAreUnsavedChanges: 'Det finns osparade förändringar.',
    confirmLeavePage: 'Bekräfta Leave -sidan',
    siteCanNotBeDeactivated: 'Webbplatsen kan inte inaktiveras eftersom har maskiner eller routrar anslutna.',
    machineCanNotBeDeactivated: 'Maskinen kan inte inaktiveras eftersom har fogar anslutna.',
    jointCanNotBeDeactivated: 'Foget kan inte inaktiveras eftersom har bultar bifogas.',
    receptionShouldContainBolt: 'Mottagningen bör innehålla minst en bult.',
    routerBrandCanNotBeDeactivated: 'Routermärke kan inte raderas eftersom har modeller bifogade.',
    areYouSureChangeStatus: 'Är du säker på att du vill ändra status?',
    areYouSureMoveBolts: 'Är du säker på att du vill flytta de valda bultarna till en annan organisation?',
    areYouSureMoveBoltsReset: 'Bulk Moving Bolts kommer att tilldela dem till en annan organisation och kommer att ändra sin status till "konfigurerad".Är du säker på att du vill fortsätta?',
    areYouSureMoveAndResolveBolts: 'Bultar med aktiva aviseringar kan inte flyttas.<br/> Vill du lösa meddelandena och flytta bultarna?',
    someRightsAreChanged: 'Vissa rättigheter ändras.',
    automaticallyLoggedOut: 'Du kommer automatiskt att loggas ut.',
    deleteWithHistory: 'Vill du ta bort rapporten med historiken?',
  },
  authentication: {
    email: 'E-post',
    password: 'Lösenord',
    newPassword: 'Nytt lösenord',
    repeatNewPassword: 'Upprepa nytt lösenord',
    keepMeLoggedIn: 'Håll mig inloggad',
    resetPassword: 'Återställa lösenord',
    login: 'Inloggning',
    passwordResetEmail: 'Ett e -postmeddelande om återställning av lösenord skickas till dig.',
    passwordResetSent: 'Ditt e -postmeddelande från lösenordet skickades till',
    passwordResetNotArrived: 'Det tar några minuter innan e -postmeddelandet kommer.Fortfarande ingen e -post efter 10 minuter?Skicka det nedan.',
    passwordsNotMatch: 'Lösenord och bekräfta lösenordet matchar inte.',
    confirmAccount: 'Bekräfta konto',
    strainlabsMonitoringSolution: 'StrainLabs -system är en komplett övervakningslösning för bultspel.',
    forMoreInformation: 'För mer information om vår analytiska programvara, %{contactus} eller gå till %{stamlabsanalytics}.',
    contactUs: 'kontakta oss',
    strainlabsAnalytics: 'Stamlabsanalys',
    savePassword: 'Spara lösenord',
  },
  profile: {
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    language: 'Språk',
    confirmNewPassword: 'Bekräfta nytt lösenord',
    currentPassword: 'Aktuellt lösenord',
    signOut: 'Utfärda',
    myAccount: 'Mitt konto',
    english: 'Engelska',
    german: 'Tysk',
    swedish: 'Svensk',
    spanish: 'Spanska',
    french: 'Franska',
    newEmail: 'Ny e -post',
    confirmNewEmail: 'Bekräfta nytt e -postmeddelande',
    emailsNotMatch: 'Nytt e -postmeddelande och bekräfta att nya e -postmeddelanden inte matchar.',
    emailShouldBeDifferent: 'Nytt e -postmeddelande bör skilja sig från det aktuella e -postmeddelandet.',
    currentEmail: 'Aktuell e -post',
    light: 'Ljus',
    dark: 'Mörk',
    notificationPreferences: 'Meddelandepreferenser',
  },
  contactUs: {
    message: 'Meddelande',
  },
  management: {
    organization: {
      companyName: 'Företagsnamn',
      companyNo: 'Företagsnummer',
      theme: 'Tema',
      vatId: 'Moms ID',
      country: 'Land',
      city: 'Stad',
      county: 'Grevskap',
      zipCode: 'Postnummer',
      street: 'Gata',
      address: 'Adress',
      phone: 'Telefon',
      company: 'Företag',
      contactPerson: 'Kontaktperson',
      companyAddress: 'Företagsadress',
      billingAddress: 'Faktureringsadress',
      useCompanyAddressForBilling: 'Använd företagets adress för fakturering',
      useContactPerson: 'Använd kontaktperson för admin',
      subscription: 'Prenumeration',
    },
    organizationRights: 'Organisationsrättigheter',
    siteRights: 'Platsrättigheter',
    configure: 'Konfigurera',
    read: 'Läsa',
    oem: 'Oem',
    euo: 'Euo',
    endUser: 'Slutanvändare |Slutanvändare',
    users: 'Användare',
    username: 'Användarnamn',
    access: 'Tillträde',
    usersRights: 'Användarnas rättigheter',
    endUserOrganization: 'Slutanvändarorganisation',
    applications: 'Ansökningar',
    changeAdmin: 'Ändra administratör',
    notificationTemplate: 'Aviseringsmall',
    notificationTemplates: 'Aviseringsmallar',
    notificationTemplateEdit: 'Aviseringsmall',
    report: 'Rapportera',
    reports: 'Rapporter',
    reportHistory: 'Rapportera historia',
    reportEdit: 'Rapporter',
    lastSent: 'Senast skickat',
    sentDatetime: 'Skickad DateTime',
    downloadLink: 'Nedladdningslänk',
    recipients: 'Mottagare',
    frequency: 'Frekvens',
    selectTemplate: 'Välj en meddelandemall eller konfigurera den till dig själv',
    selectFrequency: 'Välj en frekvens',
    reportSchedule: 'Rapportschema (UTC)',
  },
  bolts: {
    orders: 'Order',
    receptions: 'Mottagning',
    configuration: 'Konfiguration',
    boltType: 'Bulttyp',
    iso: 'Iso',
    size: 'Storlek',
    strength: 'Styrka',
    length: 'Längd',
    proofLoad: 'Bevisbelastning',
    status: 'Status',
    exportToExcel: 'Exportera till Excel',
    serialNumber: 'Serienummer',
    name: 'Namn',
    quality: 'Kvalitet',
    boltId: 'Bult -ID',
    databaseRow: 'Databasrad',
    boltDatabaseRow: 'Bultdatabasrad',
    serialNo: 'Seriell nr',
    loaded: 'Lastad',
    unloaded: 'Lossad',
    editBolt: 'Redigera bult',
    site: 'Webbplats |Plats',
    machine: 'Maskin',
    joint: 'Gemensam',
    boltName: 'Bultnamn',
    test: 'Testa',
    production: 'Produktion',
    boltHistory: 'Bulthistorik',
    tensionHistory: 'Spänningshistorik',
    changeType: 'Ändringstyp',
    changeBy: 'Förändras genom',
    deallocateBolt: 'Deallocate Bolt',
    areYouSureDeallocate: 'Är du säker på att du vill ta itu med den här bulten?',
    bulkOrganizationChange: 'Bulkorganisation förändring',
    changeOrganization: 'Förändringsorganisation',
    moveBolts: 'Flytta bultar',
    kFactor: 'K -faktor',
  },
  configuration: {
    configuration: 'Konfiguration',
    boltType: 'Bulttyp',
    createBoltType: 'Skapa bulttyp',
    editBoltType: 'Redigera bulttyp',
    createSize: 'Skapa storlek',
    editSize: 'Redigera storlek',
    createISO: 'Skapa iso',
    editISO: 'Redigera ISO',
    editStrength: 'Redigera styrka',
    createStrength: 'Skapa styrka',
    editProofLoad: 'Redigera bevisbelastning',
    createProofLoad: 'Skapa bevisbelastning',
    description: 'Beskrivning',
  },
  receptions: {
    receptionNo: 'Mottagningsnummer',
    organization: 'Organisation',
    note: 'Notera',
    status: 'Status',
    no: 'Inga.',
    quantity: 'Kvantitet',
    receptionDetails: 'Receptionsdetaljer',
    editReception: 'Redigera mottagning',
    createReception: 'Skapa mottagning',
    editReceptionDetail: 'Redigera mottagningsdetalj',
    createReceptionDetail: 'Skapa mottagningsdetalj',
    batchNr: 'Satsnummer',
    programmed: 'Programmerad',
    processPerformer: 'Processtransporter',
    new: 'Ny',
    planned: 'Planerad',
    tempCalibrated: 'Temp kalibrerad',
    ready: 'Redo',
    finished: 'Färdig',
    receivingOrganization: 'Mottagande organisation',
    finalReceivingOrganization: 'Slutlig mottagande organisation',
    moveToOrderingOrganization: 'Flytta till beställningsorganisation',
  },
  orders: {
    orderDetails: 'Beställningsuppgifter',
    orderNo: 'Beställningsnummer',
  },
  boltStatus: {
    ordered: 'Beställd',
    replaced: 'Ersatt',
    misbehaving: 'Missuppfattning',
    accepted: 'Accepterad',
    received: 'Mottagen',
    maintenance: 'Underhåll',
    configured: 'Konfigurerad',
    readyForDeploy: 'Redo för distribution',
    rogue: 'Skurk',
  },
  config: {
    sites: 'Plats',
    offset: 'Offset',
    machines: 'Maskiner',
    joints: 'Fogar',
    siteName: 'Webbplatsnamn',
    jointName: 'Fognamn',
    select: 'Välja',
    timezone: 'Tidszon',
    latitude: 'Latitud',
    longitude: 'Longitud',
    searchForOrganizations: 'Sök efter OEM och slutanvändarorganisation',
    searchForEUO: 'Sök efter slutanvändarorganisation',
    floorPlan: 'Planlösning',
    map: 'Karta',
    machineName: 'Maskinnamn',
    machineImage: 'Maskinbild',
    minimumAllowedLoad: 'Minsta kritiska belastning',
    minimumSafetyLoad: 'Minsta varningsbelastning',
    maximumSafetyLoad: 'Högsta varningsbelastning',
    apiKey: 'API -nyckel',
    wifiSSID: 'WiFi SSID',
    wifi: 'Wifi',
    downloadConfigFile: 'Ladda ner konfigurationsfilen',
    configFile: 'konfigurationsfil',
    allChildWillDeactivated: 'Denna %{objekt} och alla deras beroenden kommer att inaktiveras.',
    allChildWillActivated: 'Denna %{objekt} och alla deras beroenden kommer att aktiveras.',
    routerName: 'Routernamn',
  },
  imageUploader: {
    dragAndDrop: 'Dra och släpp här',
    uploadLogo: 'Ladda upp logotypen',
    uploadFloorPlan: 'Ladda upp planlösning',
    uploadMachineImage: 'Ladda upp maskinens bild',
    uploadJointImage: 'Ladda upp gemensam bild',
    logoAspectRatio: 'Uppladdning 4: 1 bildförhållande .png eller .jpg logotyp',
    anyAspectRatio: 'Något bildförhållande .png eller .jpg',
    changeImage: 'Ändra bild',
  },
  tooltips: {
    fullScreen: 'Helskärm',
    exitFullScreen: 'Avsluta full skärm',
    fitToWindow: 'Fönstret passar',
    actualSize: 'Faktisk storlek',
    zoomIn: 'Zooma in',
    zoomOut: 'Zooma ut',
    history: 'Historia',
    activate: 'Aktivera',
    inactivate: 'Inaktivera',
    accessRights: 'Åtkomsträttigheter',
    manageAs: 'Hantera som',
    resendConfirmationMail: 'Skicka kontonbekräftelsepost',
    view: 'Se',
    setTemporaryPassword: 'Ställ in tillfälligt lösenord',
    showPassword: 'Visa lösenord',
    hidePassword: 'Dölj lösenord',
    generatePassword: 'Generera lösenord',
    cannotEdit: 'Kan inte redigera en mottagning med aktiva bultar',
    downloadLatest: 'Ladda ner senaste',
  },
  imageMarker: {
    dropPin: 'Droppstift',
    done: 'Gjort',
  },
  routers: {
    brand: 'Märke |Märke',
    brandModels: 'Varumärkesmodeller',
    brandName: 'Varumärke',
    editRouterBrand: 'Redigera routermärke',
    createRouterBrand: 'Skapa routermärke',
    editRouterModel: 'Redigera routermodell',
    createRouterModel: 'Skapa routermodell',
    brandId: 'Varumärkes -ID',
    routerName: 'Routernamn',
    model: 'Modell',
    routerConfigTemplate: 'Routerkonfigurationsmall',
    serial: 'Serie',
    routerDescription: 'Routerbeskrivning',
    routerBrand: 'Routermärke',
    routerModel: 'Routermodell',
    deployment: 'Spridning',
    development: 'Utveckling',
  },
  analytics: {
    notification: 'Meddelande |Meddelanden',
    severity: 'Stränghet',
    title: 'Titel',
    critical: 'Kritisk |Kritisk',
    warning: 'Varning |Varningar',
    information: 'Information |Information',
    image: 'Bild',
    list: 'Lista',
    setMaintenance: 'Inställningsunderhåll',
    setActive: 'Sätta aktiv',
    machine: 'Maskin |Maskiner',
    joint: 'Joint |Fogar',
    bolt: 'Bult |Bultar',
    activeBolts: 'Aktiva bultar',
    totalBoltsNo: 'Totala bultar nr',
    router: 'Router |Routrar',
    thereAreNoMachines: 'Det finns inga maskiner.',
    thereAreNoSites: 'Det finns inga webbplatser.',
    thereAreNoJoints: 'Det finns inga leder.',
    thereAreNoBolts: 'Det finns inga bultar.',
    thereAreNoRouters: 'Det finns inga routrar.',
    thereAreNoNotifications: 'Det finns inga aviseringar.',
    variation: 'Variation',
    deviation: 'Avvikelse',
    stock: 'Stock',
    realTime: 'Realtid',
    tension: 'Spänning',
    temperature: 'Temperatur',
    battery: 'Batteri',
    time: 'Tid (webbplats)',
    rssi: 'RSSI (DB)',
    readings: 'Avläsningar',
    minutes: 'Minuter',
    hours: 'Timme',
    days: 'Dagar',
    granularity: 'Granularitet',
    resolveNotification: 'Lösning',
    resolve: 'Lösa',
    solved: 'Löst',
    unsolved: 'Olöst',
    system: 'System',
    thereAreNoDataReadings: 'Det finns inga dataväsningar för denna period.',
    filter: 'Filtrera',
    dataReadings: 'Datavläsningar',
    all: 'Alla',
    startBoltId: 'Starta bult -ID',
    endBoltId: 'Slutbult -ID',
    startBoltDatabaseRow: 'Starta bultdatabasraden',
    endBoltDatabaseRow: 'Slutbultdatabasrad',
    between: 'Mellan',
    above: 'Ovan',
    below: 'Nedan',
    outside: 'Utanför',
    minTension: 'Minspänning',
    maxTension: 'Maximal spänning',
    tensionValue: 'Spänningsvärde',
    temperatureValue: 'Temperaturvärde',
    minTemperature: 'Min temperatur',
    maxTemperature: 'Max temperatur',
    statistics: 'Statistik',
    eventTypes: 'Evenemangstyper',
    containsText: 'Innehåller text',
    logs: 'Stockar',
    SLALogs: 'SLA -loggar',
    oktaLogs: 'Okta loggar',
    usersLogins: "Användarens inloggningar",
    lastLogin: 'Sista inloggning',
    lastAccess: 'Sista åtkomst',
    accessType: 'Åtkomsttyp',
    oktaLog: 'Okta logg',
    averagesVariation: 'Medelvärden (variation)',
    individualDeviation: 'Individ (avvikelse)',
    dynamic: 'Dynamisk',
    savedFilters: 'Sparade filter',
    saveAdvancedFilter: 'Spara avancerat filter',
    filterName: 'Filternamn',
    testBench: 'Testbänk',
    details: 'Information',
    resolveSelected: 'Lösa vald',
    resolveAll: 'Lösa alla',
    resolveAllNotifications: 'Lös alla aviseringar',
    resolveForSeverity: 'Lösning för svårighetsgrad',
    startDate: 'Startdatum',
    endDate: 'Slutdatum',
    boltsInPosition: 'Bultar i läge',
    routersInPosition: 'Routrar i position',
    online: 'Online',
    offline: 'Off-line',
    availableToPosition: 'tillgänglig för position',
    overview: 'Översikt',
    lastAverageTension: 'Sista genomsnittliga spänningar',
    lastTotalTension: 'Sista totala spänningen',
    lastTension: 'Sista spänning',
    addedBy: 'Tillagd av',
    callType: 'Samtalstyp',
    callScope: 'Ringomfång',
    request: 'Begäran',
    response: 'Svar',
    success: 'Framgång',
    failed: 'Misslyckad',
    rawTension: 'Rå spänning',
    boltSelected: 'BOLT vald |Valda bultar',
    outOfDelayed: 'Av vilken {greve} har försenat kontakt |från vilken {greve} har försenat kontakten',
    missingData: 'Saknade data',
  },
  notifications: {
    tensionTooLow: 'Spänning för låg',
    tensionTooHigh: 'Spänning för hög',
    temperatureTooLow: 'Temperatur för låg',
    temperatureTooHigh: 'Temperatur för hög',
    battery: 'Batteri',
    rssi: 'RSSI',
    lostPackage: 'Förlorat paket'
  }
}