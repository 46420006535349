import validations from "./validations";

export const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const sortByAlphabetical = (field, descending = false) => {
    return (a, b) => {
        let nameA = a[field].toUpperCase();
        let nameB = b[field].toUpperCase();
        if (nameA < nameB) {
            if (descending) {
                return 1;
            }
            return -1;
        }
        if (nameA > nameB) {
            if (descending) {
                return -1;
            }
            return 1;
        }

        return 0;
    };
};

export const  numberWithSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const  generatePassword = (size = 12) => {
    const characters = 'a-z,A-Z,0-9,#';
    let charactersArray = characters.split(',');
    let CharacterSet = '';
    let password = '';

    if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
    }
    if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
    }
    if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|';
    }
    while (password === '' || validations.password(password) !== true) {
        password = '';
        for (let i = 0; i < size; i++) {
            password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
    }
    return password;
}

export const getLanguageForHeader = (language) => {
    switch (language) {
        case 'sv':
            return 'sv-SE';
        case 'fr':
            return 'fr-FR';
        case 'es':
            return 'es-ES';
        case 'de':
            return 'de-DE';
        default:
            return 'en-GB';
    }
}