import en from "./en";
import de from "./de";
import es from "./es";
import fr from "./fr";
import sv from "./sv";

const messages = {
  en,
  de,
  es,
  fr,
  sv
};
export default messages;
