export default {
  cancel: 'Stornieren',
  create: 'Erstellen',
  close: 'Schließen',
  id: 'AUSWEIS',
  delete: 'Löschen',
  save: 'Speichern',
  saveChanges: 'Änderungen speichern',
  date: 'Datum',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  pending: 'Ausstehend',
  passwordReset: 'Passwort zurücksetzen',
  deleted: 'Gelöscht',
  organization: 'Organisation',
  organizations: 'Organisationen',
  noDataAvailable: 'Keine Daten verfügbar',
  quantity: 'Menge',
  send: 'Schicken',
  reset: 'Zurücksetzen',
  exportToExcel: 'Export nach Excel',
  person: 'Person',
  status: 'Status',
  edit: 'Bearbeiten',
  admin: 'Admin',
  user: 'Benutzer',
  none: 'Keiner',
  leave: 'Verlassen',
  name: 'Name',
  type: 'Typ',
  selectAll: 'Wählen Sie alle aus',
  view: 'Sicht',
  search: 'Suchen',
  time: 'Zeit',
  yes: 'Ja',
  no: 'NEIN.',
  ok: 'OK',
  download: 'Herunterladen',
  appLauncher: {
    analytics: 'Analyse',
    management: 'Management',
    bolts: 'Bolzen',
    routers: 'Router',
    boltsManagement: 'Bolts Management',
    configuration: 'Konfiguration',
    config: 'Konfiguration',
    production: 'Produktion',
  },
  footer: {
    poweredBy: 'Angetrieben von',
    termsConditions: 'Allgemeine Geschäftsbedingungen',
    dataPrivacy: 'Daten & Datenschutz',
    contact: 'Kontakt',
  },
  success: {
    activateEmailSuccessful: 'Die Aktivierung war erfolgreich.',
    resendActivationMail: 'Die Aktivierungspost wurde erfolgreich ärgerlich.',
    resetPasswordSuccess: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
    organizationUpdated: 'Die Organisation wurde erfolgreich aktualisiert.',
    organizationAdminUpdated: 'Der Organisationsadministrator wurde erfolgreich aktualisiert.Bitte überprüfen Sie die Rechte der alten Organisation Admin.',
    organizationCreated: 'Die Organisation wurde erfolgreich erstellt.',
    messageWasSent: 'Die Nachricht wurde erfolgreich gesendet.',
    boltsWereMoved: 'Die Schrauben wurden erfolgreich bewegt.',
    passwordCopied: 'Das generierte Passwort wurde in die Zwischenablage kopiert.',
    userAccountWasActivated: 'Das Benutzerkonto wurde erfolgreich aktiviert.',
    passwordWasChange: 'Das Passwort wurde geändert.Bitte melden Sie sich an.',
  },
  errors: {
    invalidEmail: 'Ungültige E -Mail',
    required: 'Erforderlich',
    cannotBeZero: 'Dieses Feld kann nicht Null sein',
    passwordFormat: 'Das Passwort sollte ein Großbuchstaben, ein Kleinbuchstaben, eine Nummer und einen speziellen Charakter enthalten',
    numberFormat: 'Das Feld kann nur numerische Werte enthalten.',
    minimumItem: 'Sie sollten der Tabelle mindestens einen Element hinzufügen.',
    numberDecimalFormat: 'Das Feld kann nur numerische Werte mit einer Dezimalstelle enthalten.',
    numberDecimalFormatLong: 'Dieses Feld kann nur numerische Werte mit Dezimalstellen enthalten.',
    savingData: 'Es gab einen Fehler beim Speichern der Daten.',
    wrongFileType: 'Falscher Dateityp.Sie können hochladen',
    loadedShouldLarger: 'Der geladene Wert kann nicht geringer sein als der entladene Wert.',
    shouldBeLess: 'Dieser Wert sollte geringer sein als der %{Wert}.',
    uploadingFile: 'Es gab einen Fehler beim Hochladen von Dateien.',
    routerConfigTemplateFormat: 'Router -Vorlage muss ein gültiger JSON sein und enthalten: "Routerid", "organisationId", "SiteId", "Deployment", "Routern", "Apikey", "SSID", "Passwort" Felder, um gültig zu sein',
  },
  warning: {
    areYouSureDelete: 'Sind Sie sicher, dass Sie diesen Artikel löschen möchten?',
    permanentlyDeleteItem: 'Sie sind kurz davor, einen Artikel dauerhaft zu löschen.',
    thisActionPermanent: 'Diese Aktion ist dauerhaft.',
    areYouSureLeave: 'Sind Sie sicher, dass Sie diese Seite verlassen möchten?',
    thereAreUnsavedChanges: 'Es gibt nicht gerettete Änderungen.',
    confirmLeavePage: 'Bestätigen Sie die Urlaubsseite',
    siteCanNotBeDeactivated: 'Die Stelle kann nicht deaktiviert werden, da Maschinen oder Router angebracht sind.',
    machineCanNotBeDeactivated: 'Die Maschine kann nicht deaktiviert werden, da die Fugen angebracht sind.',
    jointCanNotBeDeactivated: 'Die Verbindung kann nicht deaktiviert werden, da die Schrauben befestigt sind.',
    receptionShouldContainBolt: 'Der Empfang sollte mindestens einen Bolzen enthalten.',
    routerBrandCanNotBeDeactivated: 'Die Router -Marke kann nicht gelöscht werden, da Modelle angebracht sind.',
    areYouSureChangeStatus: 'Sind Sie sicher, dass Sie den Status ändern möchten?',
    areYouSureMoveBolts: 'Sind Sie sicher, dass Sie die ausgewählten Schrauben in eine andere Organisation verschieben möchten?',
    areYouSureMoveBoltsReset: 'Bulk Moving Bolts zuordnen sie einer anderen Organisation zu und ändern ihren Status in "Konfigurieren".Bist du sicher, dass du weitermachen willst?',
    areYouSureMoveAndResolveBolts: 'Schrauben mit aktiven Benachrichtigungen können nicht bewegt werden.<br/> Möchten Sie die Benachrichtigungen lösen und die Schrauben bewegen?',
    someRightsAreChanged: 'Einige Rechte werden geändert.',
    automaticallyLoggedOut: 'Sie werden automatisch angemeldet.',
    deleteWithHistory: 'Möchten Sie den Bericht mit der Historie löschen?',
  },
  authentication: {
    email: 'E-Mail',
    password: 'Passwort',
    newPassword: 'Neues Passwort',
    repeatNewPassword: 'Neues Passwort wiederholen',
    keepMeLoggedIn: 'Eingeloggt bleiben',
    resetPassword: 'Passwort zurücksetzen',
    login: 'Login',
    passwordResetEmail: 'Eine E -Mail mit Passwort Reset wird an Sie gesendet.',
    passwordResetSent: 'Ihre E -Mail mit Passwort Reset wurde an gesendet',
    passwordResetNotArrived: 'Es dauert ein paar Minuten, bis die E -Mail ankommt.Immer noch keine E -Mail nach 10 Minuten?Senden Sie es unten erneut.',
    passwordsNotMatch: 'Passwort und Bestätigungskennwort nicht übereinstimmen.',
    confirmAccount: 'Konto bestätigen',
    strainlabsMonitoringSolution: 'Das STREALLABS -System ist eine vollständige Lösung für die Vorlastüberwachung.',
    forMoreInformation: 'Weitere Informationen zu unserer analytischen Software, %{contactus} oder zu %{StreamSanalytics}.',
    contactUs: 'Kontaktieren Sie uns',
    strainlabsAnalytics: 'Stammanalyse',
    savePassword: 'Passwort speichern',
  },
  profile: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    language: 'Sprache',
    confirmNewPassword: 'Neues Passwort bestätigen',
    currentPassword: 'Aktuelles Passwort',
    signOut: 'Abmelden',
    myAccount: 'Mein Konto',
    english: 'Englisch',
    german: 'Deutsch',
    swedish: 'Schwedisch',
    spanish: 'Spanisch',
    french: 'Französisch',
    newEmail: 'Neue E -Mail',
    confirmNewEmail: 'Bestätigen Sie neue E -Mail',
    emailsNotMatch: 'Neue E -Mails und bestätigen, dass neue E -Mails nicht übereinstimmen.',
    emailShouldBeDifferent: 'Neue E -Mail sollte sich von der aktuellen E -Mail unterscheiden.',
    currentEmail: 'Aktuelle E -Mail',
    light: 'Licht',
    dark: 'Dunkel',
    notificationPreferences: 'Benachrichtigungspräferenzen',
  },
  contactUs: {
    message: 'Nachricht',
  },
  management: {
    organization: {
      companyName: 'Name der Firma',
      companyNo: 'Firma Nr.',
      theme: 'Thema',
      vatId: 'Mehrwertsteuer id',
      country: 'Land',
      city: 'Stadt',
      county: 'County',
      zipCode: 'PLZ',
      street: 'Straße',
      address: 'Adresse',
      phone: 'Telefon',
      company: 'Unternehmen',
      contactPerson: 'Ansprechpartner',
      companyAddress: 'Firmenadresse',
      billingAddress: 'Abrechnungsadresse',
      useCompanyAddressForBilling: 'Verwenden Sie die Unternehmensadresse für die Abrechnung',
      useContactPerson: 'Verwenden Sie die Kontaktperson für Administrator',
      subscription: 'Abonnement',
    },
    organizationRights: 'Organisationsrechte',
    siteRights: 'Standortrechte',
    configure: 'Konfigurieren',
    read: 'Lesen',
    oem: 'OEM',
    euo: 'EUO',
    endUser: 'Endbenutzer |Endbenutzer',
    users: 'Benutzer',
    username: 'Benutzername',
    access: 'Zugang',
    usersRights: 'Benutzerrechte',
    endUserOrganization: 'Endbenutzerorganisation',
    applications: 'Anwendungen',
    changeAdmin: 'Admin ändern',
    notificationTemplate: 'Benachrichtigungsvorlage',
    notificationTemplates: 'Benachrichtigungsvorlagen',
    notificationTemplateEdit: 'Benachrichtigungsvorlage',
    report: 'Bericht',
    reports: 'Berichte',
    reportHistory: 'Berichtsgeschichte',
    reportEdit: 'Berichte',
    lastSent: 'Zuletzt gesendet',
    sentDatetime: 'Gesendete datetime',
    downloadLink: 'Link herunterladen',
    recipients: 'Empfänger',
    frequency: 'Frequenz',
    selectTemplate: 'Wählen Sie eine Benachrichtigungsvorlage oder konfigurieren Sie sie für sich selbst',
    selectFrequency: 'Bitte wählen Sie eine Frequenz',
    reportSchedule: 'Berichtszeitplan (UTC)',
  },
  bolts: {
    orders: 'Bestellungen',
    receptions: 'Empfänge',
    configuration: 'Konfiguration',
    boltType: 'Bolzentyp',
    iso: 'ISO',
    size: 'Größe',
    strength: 'Stärke',
    length: 'Länge',
    proofLoad: 'Beweislast',
    status: 'Status',
    exportToExcel: 'Nach Excel exportieren',
    serialNumber: 'Seriennummer',
    name: 'Name',
    quality: 'Qualität',
    boltId: 'Bolt ID',
    databaseRow: 'Datenbankzeile',
    boltDatabaseRow: 'Bolzendatenbankzeile',
    serialNo: 'Seriennummer',
    loaded: 'Geladen',
    unloaded: 'Entladen',
    editBolt: 'Bolzen bearbeiten',
    site: 'Seite |Standorte',
    machine: 'Maschine',
    joint: 'Gemeinsam',
    boltName: 'Bolzenname',
    test: 'Prüfen',
    production: 'Produktion',
    boltHistory: 'Bolzengeschichte',
    tensionHistory: 'Spannungsgeschichte',
    changeType: 'Typ ändern',
    changeBy: 'Wechseln durch',
    deallocateBolt: 'Deallocation Bolt',
    areYouSureDeallocate: 'Sind Sie sicher, dass Sie diesen Bolzen bearbeiten möchten?',
    bulkOrganizationChange: 'Massenorganisation Änderung',
    changeOrganization: 'Organisation ändern',
    moveBolts: 'Schrauben bewegen',
    kFactor: 'K -Faktor',
  },
  configuration: {
    configuration: 'Konfiguration',
    boltType: 'Bolzentyp',
    createBoltType: 'Bolzentyp erstellen',
    editBoltType: 'Bolzentyp bearbeiten',
    createSize: 'Größe erstellen',
    editSize: 'Größe bearbeiten',
    createISO: 'ISO erstellen',
    editISO: 'ISO bearbeiten',
    editStrength: 'Stärke bearbeiten',
    createStrength: 'Kraft schaffen',
    editProofLoad: 'Beweislast bearbeiten',
    createProofLoad: 'Beweislast erstellen',
    description: 'Beschreibung',
  },
  receptions: {
    receptionNo: 'Empfang Nr.',
    organization: 'Organisation',
    note: 'Notiz',
    status: 'Status',
    no: 'Nummer',
    quantity: 'Menge',
    receptionDetails: 'Empfangsdetails',
    editReception: 'Empfang bearbeiten',
    createReception: 'Empfang erstellen',
    editReceptionDetail: 'Empfangsdetail bearbeiten',
    createReceptionDetail: 'Empfangsdetail erstellen',
    batchNr: 'Chargennummer',
    programmed: 'Programmiert',
    processPerformer: 'Prozess Performer',
    new: 'Neu',
    planned: 'Geplant',
    tempCalibrated: 'Temperatur kalibriert',
    ready: 'Bereit',
    finished: 'Fertig',
    receivingOrganization: 'Empfängerorganisation',
    finalReceivingOrganization: 'Endgültige Empfangsorganisation',
    moveToOrderingOrganization: 'Umzug zur Bestellung der Organisation',
  },
  orders: {
    orderDetails: 'Bestelldetails',
    orderNo: 'Bestellung Nr.',
  },
  boltStatus: {
    ordered: 'Bestellt',
    replaced: 'Ersetzt',
    misbehaving: 'Schlecht sich benehmen',
    accepted: 'Akzeptiert',
    received: 'Erhalten',
    maintenance: 'Wartung',
    configured: 'Konfiguriert',
    readyForDeploy: 'Bereit für den Einsatz',
    rogue: 'Schurke',
  },
  config: {
    sites: 'Standorte',
    offset: 'Offset',
    machines: 'Maschinen',
    joints: 'Gelenke',
    siteName: 'Standortname',
    jointName: 'Gemeinsamer Name',
    select: 'Wählen',
    timezone: 'Zeitzone',
    latitude: 'Breite',
    longitude: 'Länge',
    searchForOrganizations: 'Suchen Sie nach OEM- und Endbenutzerorganisation',
    searchForEUO: 'Suche nach Endbenutzerorganisation',
    floorPlan: 'Grundriss',
    map: 'Karte',
    machineName: 'Maschinenname',
    machineImage: 'Maschinenbild',
    minimumAllowedLoad: 'Minimale kritische Last',
    minimumSafetyLoad: 'Minimale Warnlast',
    maximumSafetyLoad: 'Maximale Warnlast',
    apiKey: 'API -Schlüssel',
    wifiSSID: 'Wifi ssid',
    wifi: 'W-lan',
    downloadConfigFile: 'Konfigurationsdatei herunterladen',
    configFile: 'Konfigurationsdatei',
    allChildWillDeactivated: 'Dieser %{Element} und alle ihre Abhängigkeiten werden deaktiviert.',
    allChildWillActivated: 'Dieser %{Element} und alle ihre Abhängigkeiten werden aktiviert.',
    routerName: 'Routername',
  },
  imageUploader: {
    dragAndDrop: 'Drag & Drop hier',
    uploadLogo: 'Laden Sie Logo hoch',
    uploadFloorPlan: 'Laden Sie den Grundriss hoch',
    uploadMachineImage: 'Laden Sie das Maschinenbild hoch',
    uploadJointImage: 'Laden Sie das gemeinsame Bild hoch',
    logoAspectRatio: 'Hochladen 4: 1 Seitenverhältnis .png oder .jpg -Logo',
    anyAspectRatio: 'Jedes Seitenverhältnis .png oder .jpg',
    changeImage: 'Bild ändern',
  },
  tooltips: {
    fullScreen: 'Vollbild',
    exitFullScreen: 'Vollbild beenden',
    fitToWindow: 'Passform zum Fenster',
    actualSize: 'Tatsächliche Größe',
    zoomIn: 'Zoomen',
    zoomOut: 'Zoomen',
    history: 'Geschichte',
    activate: 'Aktivieren',
    inactivate: 'Inaktivieren',
    accessRights: 'Zugriffsrechte',
    manageAs: 'Verwalten as',
    resendConfirmationMail: 'Reend Account bestätigende Mail',
    view: 'Sicht',
    setTemporaryPassword: 'Stellen Sie das vorübergehende Passwort fest',
    showPassword: 'Passwort anzeigen',
    hidePassword: 'Passwort verbergen',
    generatePassword: 'Passwort generieren',
    cannotEdit: 'Keinen Empfang mit aktiven Schrauben bearbeiten kann',
    downloadLatest: 'Laden Sie die neuesten',
  },
  imageMarker: {
    dropPin: 'Stecknadel fallen',
    done: 'Erledigt',
  },
  routers: {
    brand: 'Marke |Marken',
    brandModels: 'Markenmodelle',
    brandName: 'Markenname',
    editRouterBrand: 'Router Marke bearbeiten',
    createRouterBrand: 'Erstellen Sie die Marke Router',
    editRouterModel: 'Router -Modell bearbeiten',
    createRouterModel: 'Routermodell erstellen',
    brandId: 'Marken -ID',
    routerName: 'Routername',
    model: 'Modell',
    routerConfigTemplate: 'Router -Konfigurationsvorlage',
    serial: 'Serie',
    routerDescription: 'Router Beschreibung',
    routerBrand: 'Router Marke',
    routerModel: 'Routermodell',
    deployment: 'Einsatz',
    development: 'Entwicklung',
  },
  analytics: {
    notification: 'Benachrichtigung |Benachrichtigungen',
    severity: 'Schwere',
    title: 'Titel',
    critical: 'Kritisch |Kritisch',
    warning: 'Warnung |Warnungen',
    information: 'Informationen |Information',
    image: 'Bild',
    list: 'Liste',
    setMaintenance: 'Wartung festlegen',
    setActive: 'Aktiv einstellen',
    machine: 'Maschine |Maschinen',
    joint: 'Joint |Gelenke',
    bolt: 'Bolt |Bolzen',
    activeBolts: 'Aktive Schrauben',
    totalBoltsNo: 'Gesamtschrauben Nr',
    router: 'Router |Router',
    thereAreNoMachines: 'Es gibt keine Maschinen.',
    thereAreNoSites: 'Es gibt keine Websites.',
    thereAreNoJoints: 'Es gibt keine Gelenke.',
    thereAreNoBolts: 'Es gibt keine Schrauben.',
    thereAreNoRouters: 'Es gibt keine Router.',
    thereAreNoNotifications: 'Es gibt keine Benachrichtigungen.',
    variation: 'Variation',
    deviation: 'Abweichung',
    stock: 'Aktie',
    realTime: 'Echtzeit',
    tension: 'Spannung',
    temperature: 'Temperatur',
    battery: 'Batterie',
    time: 'Zeit (Site)',
    rssi: 'RSSI (DB)',
    readings: 'Lesungen',
    minutes: 'Minuten',
    hours: 'Std',
    days: 'Tage',
    granularity: 'Granularität',
    resolveNotification: 'Benachrichtigung lösen',
    resolve: 'Lösen',
    solved: 'Gelöst',
    unsolved: 'Ungelöst',
    system: 'System',
    thereAreNoDataReadings: 'Für diesen Zeitraum gibt es keine Datenlesungen.',
    filter: 'Filter',
    dataReadings: 'Datenlesungen',
    all: 'Alle',
    startBoltId: 'Start Bolt ID',
    endBoltId: 'Ende Bolt ID',
    startBoltDatabaseRow: 'Start Bolt Database Zeile',
    endBoltDatabaseRow: 'Endschrauben -Datenbankzeile',
    between: 'Zwischen',
    above: 'Über',
    below: 'Unten',
    outside: 'Draußen',
    minTension: 'Minenspannung',
    maxTension: 'Maximale Spannung',
    tensionValue: 'Spannungswert',
    temperatureValue: 'Temperaturwert',
    minTemperature: 'Min Temperatur',
    maxTemperature: 'Maximale Temperatur',
    statistics: 'Statistiken',
    eventTypes: 'Ereignisarten',
    containsText: 'Enthält Text',
    logs: 'Protokolle',
    SLALogs: 'SLA -Protokolle',
    oktaLogs: 'Okta -Protokolle',
    usersLogins: 'Benutzeranmeldungen',
    lastLogin: 'Letzte Login',
    lastAccess: 'Letzter Zugang',
    accessType: 'Zugangstyp',
    oktaLog: 'Okta log',
    averagesVariation: 'Durchschnittswerte (Variation)',
    individualDeviation: 'Individuum (Abweichung)',
    dynamic: 'Dynamisch',
    savedFilters: 'Gespeicherte Filter',
    saveAdvancedFilter: 'Erweiterte Filter speichern',
    filterName: 'Filtername',
    testBench: 'Testbank',
    details: 'Details',
    resolveSelected: 'Ausgewählt auflösen',
    resolveAll: 'Alle auflösen',
    resolveAllNotifications: 'Alle Benachrichtigungen lösen',
    resolveForSeverity: 'Für den Schweregrad entschlossen',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    boltsInPosition: 'Bolzen in Position',
    routersInPosition: 'Router in Position',
    online: 'Online',
    offline: 'Offline',
    availableToPosition: 'zur Position verfügbar',
    overview: 'Überblick',
    lastAverageTension: 'Letzte durchschnittliche Spannung',
    lastTotalTension: 'Letzte Gesamtspannung',
    lastTension: 'Letzte Spannung',
    addedBy: 'Hinzugefügt von',
    callType: 'Anruftyp',
    callScope: 'Rufen Sie Umfang an',
    request: 'Anfrage',
    response: 'Antwort',
    success: 'Erfolg',
    failed: 'Fehlgeschlagen',
    rawTension: 'Rohe Spannung',
    boltSelected: 'Bolzen ausgewählt |Schrauben ausgewählt',
    outOfDelayed: 'von denen {count} den kontaktierten Kontakt | verspätet hat |von denen {count} den kontaktierten Kontakt verspätet hat',
    missingData: 'Fehlende Daten',
  },
  notifications: {
    tensionTooLow: 'Spannung zu niedrig',
    tensionTooHigh: 'Spannung zu hoch',
    temperatureTooLow: 'Temperatur zu niedrig',
    temperatureTooHigh: 'Temperatur zu hoch',
    battery: 'Batterie',
    rssi: 'RSSI',
    lostPackage: 'Paket verloren'
  }
}