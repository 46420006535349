export default {
  cancel: "Annuler",
  create: "Créer",
  close: "Fermer",
  id: "IDENTIFIANT",
  delete: "Supprimer",
  save: "Sauvegarder",
  saveChanges: "Enregistrer les modifications",
  date: "Date",
  active: "actif",
  inactive: "inactif",
  pending: "En attente",
  passwordReset: "Réinitialisation du mot de passe",
  deleted: "Supprimé",
  organization: "Organisation",
  organizations: "Organisations",
  noDataAvailable: "Aucune donnée disponible",
  quantity: "Quantité",
  send: "Envoyer",
  reset: "Réinitialiser",
  exportToExcel: "Exporter vers Excel",
  person: "Personne",
  status: "Statut",
  admin: "Administrer",
  edit: "Modifier",
  user: "user",
  none: "none",
  leave: "Partir",
  name: "Nom",
  type: "Taper",
  selectAll: "Sélectionnez tout",
  view: "Voir",
  search: "Recherche",
  time: "Temps",
  yes: "Oui",
  no: "Non",
  ok: "D'accord",
  download: 'Télécharger',
  appLauncher: {
    analytics: "Analytique",
    management: "Gestion",
    bolts: "Boulons",
    routers: "Routeurs",
    boltsManagement: "Gestion des boulons",
    configuration: "Configuration",
    config: "Configurer",
    production: "Production",
  },
  footer: {
    poweredBy: "Alimenté par",
    termsConditions: "Conditions générales",
    dataPrivacy: "Données et confidentialité",
    contact: "Contact",
  },
  success: {
    activateEmailSuccessful: "L'activation a réussi.",
    resendActivationMail: "Le courrier d'activation était en train de ressentir avec succès.",
    resetPasswordSuccess: "Votre mot de passe a été réinitialisé avec succès.",
    organizationUpdated: "L'organisation a été mise à jour avec succès.",
    organizationAdminUpdated: "L'administration de l'organisation a été mise à jour avec succès.Veuillez vérifier les droits de l'ancien administrateur.",
    organizationCreated: "L'organisation a été créée avec succès.",
    messageWasSent: "Le message a été envoyé avec succès.",
    boltsWereMoved: "Les boulons ont été déplacés avec succès.",
    passwordCopied: "Le mot de passe généré a été copié dans le presse-papiers.",
    userAccountWasActivated: "Le compte d'utilisateur a été activé avec succès.",
    passwordWasChange: "Le mot de passe a été modifié.Veuillez vous connecter.",
  },
  errors: {
    invalidEmail: "E-mail non valide",
    required: "Requis",
    cannotBeZero: "Ce champ ne peut pas être nul",
    passwordFormat: "Le mot de passe doit contenir une majuscule, un caractère minuscule, un nombre et un caractère spécial",
    numberFormat: "Le champ ne peut contenir que des valeurs numériques.",
    minimumItem: "Vous devez ajouter un élément minimum à la table.",
    numberDecimalFormat: "Le champ ne peut contenir des valeurs numériques qu'avec une décimale.",
    numberDecimalFormatLong: "Ce champ ne peut contenir des valeurs numériques qu'avec des décimales.",
    savingData: "Il y a eu une erreur lors de l'enregistrement des données.",
    wrongFileType: "Type de fichier mauvais.Vous pouvez télécharger",
    loadedShouldLarger: "La valeur chargée ne peut pas être inférieure à la valeur non chargée.",
    shouldBeLess: "Cette valeur doit être inférieure à la valeur% {valeur}.",
    uploadingFile: "Il y a eu une erreur lors du téléchargement du fichier.",
    routerConfigTemplateFormat: "Le modèle de routeur doit être un JSON valide et contenir: 'Routerid', 'OrganizationId', 'SiteID', 'Deployment', 'Router'",
  },
  warning: {
    areYouSureDelete: "Êtes-vous sûr de vouloir supprimer cet élément?",
    permanentlyDeleteItem: "Vous êtes sur le point de supprimer définitivement un article.",
    thisActionPermanent: "Cette action est permanente.",
    areYouSureLeave: "Êtes-vous sûr de vouloir quitter cette page?",
    thereAreUnsavedChanges: "Il y a des changements non sauvés.",
    confirmLeavePage: "Confirmer la page de congé",
    siteCanNotBeDeactivated: "Le site ne peut pas être désactivé depuis des machines ou des routeurs.",
    machineCanNotBeDeactivated: "La machine ne peut pas être désactivée car les joints ont-ils des joints attachés.",
    jointCanNotBeDeactivated: "L'articulation ne peut pas être désactivée car les boulons ont-ils été attachés.",
    receptionShouldContainBolt: "La réception doit contenir au moins un boulon.",
    routerBrandCanNotBeDeactivated: "La marque de routeur ne peut pas être supprimée car les modèles ont des modèles.",
    areYouSureChangeStatus: "Êtes-vous sûr de vouloir modifier le statut?",
    areYouSureMoveBolts: "Êtes-vous sûr de vouloir déplacer les boulons sélectionnés vers une autre organisation?",
    areYouSureMoveBoltsReset: "Les boulons de déménagement en vrac les attribueront à une autre organisation et changeront leur statut en 'configuré'.Êtes-vous sûr de vouloir continuer?",
    areYouSureMoveAndResolveBolts: "Les boulons avec des notifications actives ne peuvent pas être déplacés.<br/> Souhaitez-vous résoudre les notifications et déplacer les boulons?",
    someRightsAreChanged: "Certains droits sont modifiés.",
    automaticallyLoggedOut: "Vous serez automatiquement déconnecté.",
    deleteWithHistory: "Souhaitez-vous supprimer le rapport avec l'historique ?",
  },
  authentication: {
    email: "E-mail",
    password: "Mot de passe",
    newPassword: "Nouveau mot de passe",
    repeatNewPassword: "Répéter un nouveau mot de passe",
    keepMeLoggedIn: "Rester connecté",
    resetPassword: "Réinitialiser le mot de passe",
    login: "Se connecter",
    passwordResetEmail: "Un e-mail de réinitialisation de mot de passe vous sera envoyé.",
    passwordResetSent: "Votre e-mail de réinitialisation de mot de passe a été envoyé à",
    passwordResetNotArrived: "Il faut quelques minutes pour que l'e-mail arrive.Toujours pas de courriel après 10 minutes?Rendez-le ci-dessous.",
    passwordsNotMatch: "Mot de passe et confirmer le mot de passe ne correspond pas.",
    confirmAccount: "Confirmer le compte",
    strainlabsMonitoringSolution: "StrainLabs System est une solution complète de surveillance de précharge du boulon.",
    forMoreInformation: "Pour plus d'informations sur notre logiciel analytique,% {ContactUS} ou accédez à% {SrainLabsanalytics}.",
    contactUs: "Contactez-nous",
    strainlabsAnalytics: "StrainLabs Analytics",
    savePassword: "Enregistrer le mot de passe",
  },
  profile: {
    firstName: "Prénom",
    lastName: "Nom de famille",
    language: "Langue",
    confirmNewPassword: "Confirmer un nouveau mot de passe",
    currentPassword: "Mot de passe actuel",
    signOut: "Se déconnecter",
    myAccount: "Mon compte",
    english: "english",
    german: "german",
    swedish: "swedish",
    spanish: 'Espagnol',
    french: 'Français',
    newEmail: "Nouveau e-mail",
    confirmNewEmail: "Confirmer le nouvel e-mail",
    emailsNotMatch: "Nouvel e-mail et confirmez que les nouveaux e-mails ne correspondent pas.",
    emailShouldBeDifferent: "Le nouvel e-mail doit être différent de l'e-mail actuel.",
    currentEmail: "E-mail actuel",
    light: "Lumière",
    dark: "Sombre",
    notificationPreferences: "Préférences de notification",
  },
  contactUs: {
    message: "Message",
  },
  management: {
    organization: {
      companyName: "Nom de l'entreprise",
      companyNo: "N ° d'entreprise",
      theme: "Thème",
      vatId: "ID de la cuve",
      country: "Pays",
      city: "Ville",
      county: "Comté",
      zipCode: "Code postal",
      street: "Rue",
      address: "Adresse",
      phone: "Téléphone",
      company: "Entreprise",
      contactPerson: "Personne de contact",
      companyAddress: "Adresse de l'entreprise",
      billingAddress: "adresse de facturation",
      useCompanyAddressForBilling: "Utiliser l'adresse de l'entreprise pour la facturation",
      useContactPerson: "Utiliser la personne de contact pour l'administrateur",
      subscription: "Abonnement",
    },
    organizationRights: "Droits de l'organisation",
    siteRights: "Droits du site",
    configure: "Configurer",
    read: "Lire",
    oem: "Socle",
    euo: "EUO",
    endUser: "Utilisateur final |Utilisateurs finaux",
    users: "Utilisateurs",
    username: "Nom d'utilisateur",
    access: "Accéder",
    usersRights: "Droits des utilisateurs",
    endUserOrganization: "Organisation des utilisateurs finaux",
    applications: "Applications",
    changeAdmin: "Changer d'administration",
    notificationTemplate: "Modèle de notification",
    notificationTemplates: "Modèles de notification",
    notificationTemplateEdit: "Modèle de notification",
    report: "Rapport",
    reports: "Rapports",
    reportHistory: "Signaler l'historique",
    reportEdit: "Rapports",
    lastSent: "Envoi du dernier",
    sentDatetime: "Envoyé DateTime",
    downloadLink: "Lien de téléchargement",
    recipients: "Récipiendaire",
    frequency: "Fréquence",
    selectTemplate: "Sélectionnez un modèle de notification ou configurez-le par vous-même",
    selectFrequency: "Veuillez sélectionner une fréquence",
    reportSchedule: 'Planification des rapports (UTC)',
  },
  bolts: {
    orders: "Ordres",
    receptions: "Réceptions",
    configuration: "Configuration",
    boltType: "Type de boulon",
    iso: "ISO",
    size: "Taille",
    strength: "Force",
    length: "Longueur",
    proofLoad: "Charge de preuve",
    status: "Statut",
    exportToExcel: "Exporter vers Excel",
    serialNumber: "Numéro de série",
    name: "Nom",
    quality: "Qualité",
    boltId: "ID de boulon",
    databaseRow: "Ligne de base de données",
    boltDatabaseRow: "Ligne de base de données de boulons",
    serialNo: "SERIE NO",
    loaded: "élélé",
    unloaded: "unloaded",
    editBolt: "Modifier le boulon",
    site: "Site |Sites",
    machine: "Machine |Machines",
    joint: "Joint |Articulations",
    boltName: "Nom de boulon",
    test: "Test",
    production: "Production",
    boltHistory: "Historique des boulons",
    tensionHistory: "Historique des tensions",
    changeType: "Type de modification",
    changeBy: "Changer",
    deallocateBolt: "Bolt de DealLocation",
    areYouSureDeallocate: "Êtes-vous sûr de vouloir traiter ce boulon?",
    bulkOrganizationChange: "Changement de l'organisation en vrac",
    changeOrganization: "Organisation de changement",
    moveBolts: "Déplacer les boulons",
    kFactor: "K facteur",
  },
  configuration: {
    configuration: "Configuration",
    boltType: "Type de boulon",
    createBoltType: "Créer un type de boulon",
    editBoltType: "Modifier le type de boulon",
    createSize: "Créer une taille",
    editSize: "Modifier la taille",
    createISO: "Créer ISO",
    editISO: "Modifier ISO",
    editStrength: "Modifier la force",
    createStrength: "Créer une force",
    editProofLoad: "Modifier la charge de preuve",
    createProofLoad: "Créer une charge de preuve",
    description: "Description",
  },
  receptions: {
    receptionNo: "Réception n °",
    organization: "Organisation",
    note: "note",
    status: "Status",
    no: "Non.",
    quantity: "Quantité",
    receptionDetails: "Détails de réception",
    editReception: "Modifier la réception",
    createReception: "Créer une réception",
    editReceptionDetail: "Modifier le détail de réception",
    createReceptionDetail: "Créer un détail de réception",
    batchNr: "Numéro de lot",
    programmed: "Programmé",
    processPerformer: "Processeur",
    new: "new",
    planned: "-planifié",
    tempCalibrated: "TEMP calibré",
    ready: "Prêt",
    finished: "finished",
    receivingOrganization: "Organisation de réception",
    finalReceivingOrganization: "Organisation de réception finale",
    moveToOrderingOrganization: "Passez à l'organisation de commande",
  },
  orders: {
    orderDetails: "Détails de la commande",
    orderNo: "N ° de commande",
  },
  boltStatus: {
    ordered: "ordre",
    replaced: "Remplacé",
    misbehaving: "Malheur",
    accepted: "Accepté",
    received: "received",
    maintenance: "Entretien",
    configured: "Configuré",
    readyForDeploy: "Prêt pour le déploiement",
    rogue: "Voyou",
  },
  config: {
    sites: "Sites",
    offset: "Compenser",
    machines: "Machines",
    joints: "Articulations",
    siteName: "Nom du site",
    jointName: "Nom conjoint",
    select: "Sélectionner",
    timezone: "Fuseau horaire",
    latitude: "Latitude",
    longitude: "Longitude",
    searchForOrganizations: "Rechercher l'OME et l'organisation des utilisateurs finaux",
    searchForEUO: "Rechercher l'organisation des utilisateurs finaux",
    floorPlan: "Plan d'étage",
    map: "Carte",
    machineName: "Nom de machine",
    machineImage: "Image machine",
    minimumAllowedLoad: 'Charge critique minimale',
    minimumSafetyLoad: 'Charge d’avertissement minimale',
    maximumSafetyLoad: 'Charge d’avertissement maximale',
    apiKey: "clé api",
    wifiSSID: "Wifi ssid",
    wifi: "Wifi",
    downloadConfigFile: "Fichier de configuration download",
    configFile: "config file",
    allChildWillDeactivated: "Ce% {item} et toutes leurs dépendances seront désactivés.",
    allChildWillActivated: "this% {item} et toutes leurs dépendances seront activées.",
    routerName: "Nom du routeur",
  },
  imageUploader: {
    dragAndDrop: "Glisser et tomber ici",
    uploadLogo: "Télécharger le logo",
    uploadFloorPlan: "Plan d'étage UPload",
    uploadMachineImage: "Télécharger l'image de la machine",
    uploadJointImage: "Télécharger l'image conjointe",
    logoAspectRatio: "Téléchargez le logo d'aspect 4: 1.",
    anyAspectRatio: "Tout rapport d'aspect .png ou .jpg",
    changeImage: "Changer d'image",
  },
  tooltips: {
    fullScreen: "Plein écran",
    exitFullScreen: "exit full screen",
    fitToWindow: "fit à la fenêtre",
    actualSize: "Taille réelle",
    zoomIn: "Zoomer",
    zoomOut: "Zoom out",
    history: "Histoire",
    activate: "Activer",
    inactivate: "Inactiver",
    accessRights: "Droits d'accès",
    manageAs: "Gérer comme",
    resendConfirmationMail: "Repenser le courrier de confirmation du compte",
    view: "Voir",
    setTemporaryPassword: "Définir le mot de passe temporaire",
    showPassword: "Montrer le mot de passe",
    hidePassword: "Masquer le mot de passe",
    generatePassword: "Générer un mot de passe",
    cannotEdit: "Impossible de modifier une réception avec des boulons actifs",
    downloadLatest: "Télécharger la dernière version",
  },
  imageMarker: {
    dropPin: "Goupille de goutte",
    done: "Fait",
  },
  routers: {
    brand: "Marque |Marques",
    brandModels: "Modèles de marque",
    brandName: "Nom de marque",
    editRouterBrand: "Modifier la marque Router",
    createRouterBrand: "Créer une marque de routeur",
    editRouterModel: "Modifier le modèle du routeur",
    createRouterModel: "Créer un modèle de routeur",
    brandId: "Identifiant de marque",
    routerName: "Nom du routeur",
    model: "Modèle",
    routerConfigTemplate: "Modèle de configuration du routeur",
    serial: "En série",
    routerDescription: "Description du routeur",
    routerBrand: "Marque de routeur",
    routerModel: "Modèle de routeur",
    deployment: "Déploiement",
    development: "Développement",
  },
  analytics: {
    notification: "Notification |Notifications",
    severity: "Gravité",
    title: "Titre",
    critical: "Critique |Critique",
    warning: "AVERTISSEMENT |Avertissements",
    information: "Informations |Information",
    image: "Image",
    list: "Liste",
    setMaintenance: "Définir l'entretien",
    setActive: "Définir actif",
    bolt: "Boulon |Boulons",
    activeBolts: "Boulons actifs",
    totalBoltsNo: "Boulons totaux Non",
    machine: "Machine |Machines",
    joint: "Joint |Articulations",
    router: "Routeur |Routeurs",
    thereAreNoMachines: "Il n'y a pas de machines.",
    thereAreNoSites: "Il n'y a pas de sites.",
    thereAreNoJoints: "Il n'y a pas de joints.",
    thereAreNoBolts: "Il n'y a pas de boulons.",
    thereAreNoRouters: "Il n'y a pas de routeurs.",
    thereAreNoNotifications: "Il n'y a pas de notifications.",
    variation: "Variation",
    deviation: "Déviation",
    stock: "Action",
    realTime: "Temps réel",
    tension: "Tension",
    temperature: "Température",
    battery: "Batterie",
    time: "Temps (site)",
    rssi: "RSSI (DB)",
    readings: "Lectures",
    minutes: "Minutes",
    hours: "Heures",
    days: "Jours",
    granularity: "Granularité",
    resolveNotification: "Résoudre la notification",
    resolve: "Résoudre",
    solved: "solved",
    unsolved: "Non résolu",
    system: "Système",
    thereAreNoDataReadings: "Il n'y a pas de lectures de données pour cette période.",
    filter: "Filtre",
    dataReadings: "Lectures de données",
    all: "all",
    startBoltId: "ID de boulon de démarrage",
    endBoltId: "ID du boulon d'extrémité",
    startBoltDatabaseRow: "Démarrer la ligne de base de données du boulon",
    endBoltDatabaseRow: "Ligne de base de données du boulon d'extrémité",
    between: "Entre",
    above: "Au-dessus de",
    below: "Ci-dessous",
    outside: "Dehors",
    minTension: "Tension min",
    maxTension: "Tension maximale",
    tensionValue: "Valeur de tension",
    temperatureValue: "Valeur de température",
    minTemperature: "Température min",
    maxTemperature: "Température maximale",
    statistics: "Statistiques",
    eventTypes: "Types d'événements",
    containsText: "Contient du texte",
    logs: "Bûches",
    SLALogs: "Journaux SLA",
    oktaLogs: "Journaux Okta",
    usersLogins: "Connexions des utilisateurs",
    lastLogin: "Dernière connexion",
    lastAccess: "Dernier accès",
    accessType: "Type d'accès",
    oktaLog: "Journal okta",
    averagesVariation: "Moyennes (variation)",
    individualDeviation: "Individu (déviation)",
    dynamic: "Dynamique",
    savedFilters: "Filtres enregistrés",
    saveAdvancedFilter: "Enregistrer le filtre avancé",
    filterName: "Nom de filtre",
    testBench: "Banc d'essai",
    details: "Détails",
    resolveSelected: "Résoudre sélectionné",
    resolveAll: "Résoudre tout",
    resolveAllNotifications: "Résoudre toutes les notifications",
    resolveForSeverity: "Résoudre la gravité",
    startDate: "Date de début",
    endDate: "Date de fin",
    boltsInPosition: "Boulons en position",
    routersInPosition: "Routeurs en position",
    online: "En ligne",
    offline: "Hors ligne",
    availableToPosition: "Disponible en position",
    overview: "Aperçu",
    lastAverageTension: "Dernière tension moyenne",
    lastTotalTension: "Dernière tension totale",
    lastTension: "Dernière tension",
    addedBy: "Ajouté par",
    callType: "Type d'appel",
    callScope: "Pode d'appel",
    request: "Demande",
    response: "Réponse",
    success: "Succès",
    failed: "Échoué",
    rawTension: "Tension brute",
    boltSelected: "Boulon sélectionné |Boulons sélectionnés",
    outOfDelayed: "De quoi {comte} a retardé le contact |dont {count} a retardé le contact",
    missingData: 'Données manquantes',
  },
  notifications: {
    tensionTooLow: 'Tension trop basse',
    tensionTooHigh: 'Tension trop élevée',
    temperatureTooLow: 'Température trop basse',
    temperatureTooHigh: 'Température trop élevée',
    battery: 'Batterie',
    rssi: 'RSSI',
    lostPackage: 'Paquet perdu'
  }
}